import AboutComponent from '../components/about/AboutComponent';
import AboutTitleComponent from '../components/titles/AboutTitleComponent';
import VideoAboutComponent from '../components/video/VideoAboutComponent';
import TheoryAboutCompnent from '../components/theory/TheoryAboutCompnent';
import TeamComponent from '../components/team/TeamComponent';
import NewslleterComponent from '../components/newslletter/NewsletterComponent';
import ContactComponent from '../components/contact/ContactComponent';
import ServiceAboutComponent from '../components/service/ServiceAboutComponent';

//Style CSS
import '../App.css';
import '../assets/css/bootstrap.min.css'
import '../assets/css/animate.css'
import '../assets/font/flaticon.css'
import '../assets/css/owl.carousel.min.css'
import '../assets/css/owl.theme.default.min.css'
import '../assets/css/magnific-popup.css'
import '../assets/css/meanmenu.css'
import '../assets/css/style.css'
import '../assets/css/dark.css'
import '../assets/css/responsive.css'
import ProgressAboutComponent from '../components/progress/ProgressAboutComponent';





const About = () => {
    return (
        <div>
            <AboutTitleComponent />
            <AboutComponent />
            <TheoryAboutCompnent />
            <VideoAboutComponent />
            <TeamComponent />
            <ServiceAboutComponent />
            <ProgressAboutComponent />
            <ContactComponent />
            <NewslleterComponent />
        </div>
    )
}

export default About
