import gestao from  "../../assets/img/blog/gestao.webp"
import process from  "../../assets/img/blog/process.webp"
import consultoria from  "../../assets/img/blog/consultoria.webp"
import bpo from  "../../assets/img/blog/bpo.webp"
import lgpd from  "../../assets/img/blog/lgpd.webp"
import tributario from  "../../assets/img/blog/tributario.webp"


const FeaturedComponent = () => {
    return (
        <section id="service" className="blog-section pt-100 pb-70">
            <div className="container">

            <div className="section-title text-center">
                    <span>Serviços</span>
                    <h2>Experiência para mudar o seu negócio</h2>
                    <p>Temos uma variedade de serviços de consultoria para ajudar a atingir suas metas e conquistar seus objetivos.</p>
                </div>
                
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <div><img src={gestao} alt="gestao"></img></div>
                            </div>
                            <div className="blog-text">

                                <h3>Diagnóstico e Gestão Organizacional</h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Análise Organizacional</li>
                                        <li>- Análise de Conformidade</li>
                                        <li>- Estruturação de Departamento</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <div><img src={process} alt="processos"></img></div>
                            </div>
                            <div className="blog-text">

                                <h3>Gestão de Pessoas e Processos</h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Análise SWOT</li>
                                        <li>- Plano de Cargos e Salários</li>
                                        <li>- Automação de Processos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="blog-card">
                            <div className="blog-img">
                                <div><img src={consultoria} alt="consultoria"></img></div>
                            </div>
                            <div className="blog-text">

                                <h3>Consultoria e Assessoria</h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Planejamento Estratégico</li>
                                        <li>- Planejamento Financeiro</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <div><img src={tributario} alt="Analise Tributária"></img></div>
                            </div>
                            <div className="blog-text">

                                <h3>Análise e Reestruturação Tributária</h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Enquadramento Tributário</li>
                                        <li>- Análise Prescricional</li>
                                        <li>- Planejamento Tributário</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <div><img src={lgpd} alt="compilance e lgpd"></img></div>
                            </div>
                            <div className="blog-text">

                                <h3>Compliance e LGPD</h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Políticas de Segurança</li>
                                        <li>- Tratamento de Informações</li>
                                        <li>- Treinamento de Colaboradores</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="blog-card">
                            <div className="blog-img">
                                <div><img src={bpo} alt="bpo e terceirizaçao"></img></div>
                            </div>
                            <div className="blog-text">

                                <h3>BPO/Terceirização</h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Administrativo</li>
                                        <li>- Financeiro</li>
                                        <li>- Contábil</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default FeaturedComponent
