import About from "../../assets/img/about/2.jpeg"

const AboutComponent = () => {
    return (
        <section className="about-section pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="about-image">
                            <img src={About} alt="about"></img>
                        </div>
                    </div>

                    <div className="col-lg-6 offset-lg-1">
                        <div className="about-text">
                            <div className="section-title">
                                <span>Sobre nós</span>
                                <h2>A maneira moderna de fazer negócios de sucesso</h2>
                            </div>

                            <div className="row">
                                <div className="col-md-3 col-sm-4">
                                    <div className="about-us">
                                        <i className="flaticon-podium icon-one"></i>
                                        <p>Segurança</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="about-us">
                                        <i className="flaticon-customer-service icon-two"></i>
                                        <p>Confiança</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="about-us">
                                        <i className="flaticon-handshake icon-three"></i>
                                        <p>Responsabilidade</p>
                                    </div>
                                </div>
                            </div>

                            <p>Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div className="theme-button">
                                <a href="about.html" className="default-btn">Leia mais</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutComponent
