const ServiceComponent = () => {
    return (
        <div className="service-section service-style-two mb-70">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 service-img">
                    </div>
                    <div className="col-md-6 offset-md-6 p-0">
                        <div className="service-text">
                            <h2>Metodologia Ágil</h2>
                            <p className="text-white pr-5">
                                Utilizamos uma metodologia de trabalho que busca diagnosticar eventuais falhas nos processos internos dos clientes, visando organizar a estrutura empresarial, administrando processos, treinando e capacitando colaboradores para que o seu negócio possa ser completo com uma gestão auditável.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceComponent
