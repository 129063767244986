import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Logo1 from  "../../assets/img/clients/Artboard 1.webp"
import Logo2 from  "../../assets/img/clients/Artboard 2.webp"
import Logo4 from  "../../assets/img/clients/Artboard 4.webp"
import Logo5 from  "../../assets/img/clients/Artboard 5.webp"
import Logo6 from  "../../assets/img/clients/Artboard 6.webp"
import Logo7 from  "../../assets/img/clients/Artboard 7.webp"
import Logo8 from  "../../assets/img/clients/Artboard 8.webp"
import Logo9 from  "../../assets/img/clients/Artboard 9.webp"
import Logo11 from  "../../assets/img/clients/Artboard 11.webp"
import Logo12 from  "../../assets/img/clients/Artboard 12.webp"
import Logo13 from  "../../assets/img/clients/Artboard 13.webp"
import Logo14 from  "../../assets/img/clients/Artboard 14.webp"
import Logo15 from  "../../assets/img/clients/Artboard 15.webp"
import Logo16 from  "../../assets/img/clients/Artboard 16.webp"
import Logo17 from  "../../assets/img/clients/Artboard 17.webp"
import Logo18 from  "../../assets/img/clients/Artboard 18.webp"
import Logo19 from  "../../assets/img/clients/Artboard 19.webp"
import Logo20 from  "../../assets/img/clients/Artboard 20.webp"
import Logo21 from  "../../assets/img/clients/Artboard 21.webp"
import Logo22 from  "../../assets/img/clients/Artboard 22.webp"


const responsive =   {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    }


const TeamComponent = () => {
    return (
        <section id="clients" className="team-section pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span>Nossos Clientes</span>
                    <h2>Conheça nossos clientes e parceiros</h2>
                </div>
                <div className="row">
                    <OwlCarousel className={'owl-theme'} autoplay responsive={responsive} loop>
                    <div className="">
                        <div className="team-card slider-items item">
                            <div className="team-img">
                                <img src={Logo1} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card slider-items">
                            <div className="team-img">
                                <img src={Logo2} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo4} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo5} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo6} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo7} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo8} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo9} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo11} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo12} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo13} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo14} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo15} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo16} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo17} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo18} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo19} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo20} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo21} alt="team member"></img>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="team-card">
                            <div className="team-img">
                                <img src={Logo22} alt="team member"></img>
                            </div>
                        </div>
                    </div>
                    </OwlCarousel>
                    
                </div>
            </div>
        </section>
    )
}

export default TeamComponent
