const ProgressAboutComponent = () => {
    return (
        <div className="progress-section progress-style-two">
        <div className="container-fluid">
            <div className="row">
                
                <div className="col-md-6 p-0">
                    <div className="progress-text">
                        <h2>Consultores Especialistas</h2>
                        <p>Somos um time de consultores especialistas nas mais diversas áreas, com o objetivo claro e foco em viabilizar soluções para a melhor gestão do seu negócio.</p>
                    </div>
                </div>
                <div className="col-md-6 progress-image">
                </div>
            </div>
        </div>
    </div>
    )
}

export default ProgressAboutComponent
