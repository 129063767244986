const FeaturedTwoComponent = () => {
    return (
        <section className="blog-section pt-100 pb-70">
            <div className="container">

            <div className="section-title text-center">
                    <span>Serviços</span>
                    <h2>Experiência para mudar o seu negócio</h2>
                    <p>Temos uma variedade de serviços de consultoria para ajudar a atingir suas metas e conquistar seus objetivos.</p>
                </div>
                
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <a href="blog-details.html"><img src="assets/img/blog/1.jpg" alt="blog"></img></a>
                            </div>
                            <div className="blog-text">

                                <h3><a href="blog-details.html">Diagnóstico e Gestão Organizacional</a></h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Análise Organizacional</li>
                                        <li>- Análise de Conformidade</li>
                                        <li>- Estruturação de Departamento</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <a href="blog-details.html"><img src="assets/img/blog/2.jpg" alt="blog"></img></a>
                            </div>
                            <div className="blog-text">

                                <h3><a href="blog-details.html">Gestão de Pessoas e Processos</a></h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Análise SWOT</li>
                                        <li>- Plano de Cargos e Salários</li>
                                        <li>- Automação de Processos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="blog-card">
                            <div className="blog-img">
                                <a href="blog-details.html"><img src="assets/img/blog/3.jpg" alt="blog"></img></a>
                            </div>
                            <div className="blog-text">

                                <h3><a href="blog-details.html">Consultoria e Assessoria</a></h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Planejamento Estratégico</li>
                                        <li>- Planejamento Financeiro</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <a href="blog-details.html"><img src="assets/img/blog/1.jpg" alt="blog"></img></a>
                            </div>
                            <div className="blog-text">

                                <h3><a href="blog-details.html">Análise e Reestruturação Tributária</a></h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Enquadramento Tributário</li>
                                        <li>- Análise Prescricional</li>
                                        <li>- Planejamento Tributário</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <div className="blog-img">
                                <a href="blog-details.html"><img src="assets/img/blog/2.jpg" alt="blog"></img></a>
                            </div>
                            <div className="blog-text">

                                <h3><a href="blog-details.html">Compilance e LGPD</a></h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Políticas de Segurança</li>
                                        <li>- Tratamento de Informações</li>
                                        <li>- Treinamento de Colaboradores</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="blog-card">
                            <div className="blog-img">
                                <a href="blog-details.html"><img src="assets/img/blog/3.jpg" alt="blog"></img></a>
                            </div>
                            <div className="blog-text">

                                <h3><a href="blog-details.html">BPO/Terceirização</a></h3>

                                <div className="post-info">
                                    <ul className="topic-features">
                                        <li>- Administrativo</li>
                                        <li>- Financeiro</li>
                                        <li>- Contábil</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default FeaturedTwoComponent
