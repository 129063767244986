import iconWhats from "../../assets/img/button-whats/icon_whatsapp.png"

const BannerComponent = () => {
    return (
        <div id="banner" className="main-banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="banner-text">
                                    <h1>Administração, Finanças e Negócios</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="whatsapp_chat">
    <input id="wa_toggle" type="checkbox" />
    <label for="wa_toggle"></label>
    <div id="wa_popup">
        <div className="wa_head">
            <div className="wa_title">Fale Conosco</div>
                <div className="wa_subtitle">Entre em contato para podermos te ajudar!</div>
            </div>
            <div className="wa_content">
                <ul className="wa_list">
                    <li className="wa_contact">
                        <a href="https://api.whatsapp.com/send?phone=5561998653345&text=Ol%C3%A1%2C%20gostaria%20de%20conhecer%20a%20BMJ!">
                        <div className="wa_avatar">
                        <img src={iconWhats} alt="Icone Whats" itemprop="image" width="60" height="60"></img>
                        </div>
                            <div className="wa_text">
                                <div className="wa_name font-color-brown" itemprop="name">Whatsapp</div>
                                <div className="wa_role font-color-silver-dark" itemprop="agent">Envie sua mensagem</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        </div>
        </div>
    )
}

export default BannerComponent
