const CardContactComponent = () => {
    return (
        <div className="contact-area">
            <div className="container">
                <div className="row contact-box">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-card">
                            <i className="flaticon-pin"></i>
                            <h3>Localização</h3>
                            <ul>
                                <li>SHVP - Rua 10, No. 323/1, Lote 01 - Brasília/DF</li>
                            </ul>
                        </div>
                    </div>
    
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-card">
                            <i className="flaticon-call"></i>
                            <h3>Telefone</h3>
                            <ul>
                                <li>(61) 99865-3345</li> <br/>
                            </ul>
                        </div>
                    </div>
    
                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="contact-card">
                            <i className="flaticon-envelope"></i>
                            <h3>E-mail</h3>
                            <ul>
                                <li>mjcontab@terra.com.br</li> <br/>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardContactComponent
