import ClientComponent from "../components/clients/ClientComponent"
import ClientTitleComponent from "../components/titles/ClientTitleComponent"

const Portifolio = () => {
    return (
        <div>
            <ClientTitleComponent />
            <ClientComponent />
        </div>
    )
}

export default Portifolio
