import Contact from "../../assets/img/contact.webp"
import Shape from "../../assets/img/shapes/1.webp"

const ContactComponent = () => {
    return (
        <div id="contact" className="contact-section pb-100 pt-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 p-0 contact-img">
                        <img src={Contact} alt="contact"></img>
                    </div>

                    <div className="col-lg-6 p-0">
                        <div className="contact-form">
                            <div className="contact-text">
                                <h3>Nós queremos ouvir você</h3>
                                <p>Fique a vontade e nos envie uma mensagem. Nós vamos responder você.</p>
                            </div>

                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-md-12 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" name="name" id="name" className="form-control" required data-error="Digite seu nome" placeholder="Nome"></input>
                                                <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-6">
                                        <div className="form-group">
                                            <input type="email" name="email" id="email" className="form-control" required data-error="Digite seu e-mail" placeholder="E-mail"></input>
                                                <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" name="msg_subject" id="msg_subject" className="form-control" required data-error="Digite o assunto" placeholder="Assunto"></input>
                                                <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="message-field" id="message" cols="30" rows="5" required data-error="Digite sua mensagem" placeholder="Mensagem"></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn contact-btn">
                                            Enviar Mensagem
                                        </button>
                                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>

                            <img src={Shape} className="contact-shape" alt="shape"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactComponent
