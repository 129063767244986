const MapComponent = () => {
    return (
        <div class="map">
            <div class="container-fluid p-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.2799005028537!2d-47.913584973451044!3d-15.789188492007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3a88fa8fad13%3A0x9a20b86b2e486332!2sPlatinum%20Office!5e0!3m2!1spt-BR!2sbr!4v1662487485556!5m2!1spt-BR!2sbr"></iframe>
            </div>
        </div>
    )
}

export default MapComponent
