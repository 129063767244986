const VideoTwoComponent = () => {
    return (
        <div className="video-style-two">
            <div className="container-fluid">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="video-text text-center">
                            <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" className="popup-vimeo">
                                <i className="flaticon-play"></i>
                            </a>
                            <p>Assista nosso vídeos</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoTwoComponent
