import Logo from "../../assets/img/logo.png";
import Logo2 from "../../assets/img/logo-two.png";

import { Link } from "react-scroll";
/* import { Link } from "react-router-dom"; */



const NavComponent = () => {
    return (
        <div className="navbar-area">

            <div className="mobile-nav">
                <Link to="/" className="logo">
                    <img src={Logo} alt="logo"></img>
                </Link>
            </div>

            <div className="main-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} className="main-logo" width="200" alt="logo"/>
                            <img src={Logo2} className="white-logo" width="200" alt="logo"/>
                        </Link>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item menu-link">
                                    <Link activeClass="active" to="banner" spy={true} offset={-250} smooth={true} duration={500} className="nav-link">Home</Link>
                                </li>
                                <li className="nav-item menu-link">
                                    <Link activeClass="active" to="about" spy={true} smooth={true} offset={-300} duration={500}  className="nav-link">Sobre Nós</Link>
                                </li>
                                <li className="nav-item menu-link">
                                    <Link activeClass="active" to="service" spy={true} offset={-150} smooth={true} duration={500}  className="nav-link">Serviços</Link>
                                </li>
                                <li className="nav-item menu-link">
                                    <Link activeClass="active" to="clients" spy={true} smooth={true} offset={-100} duration={500}  className="nav-link">Clientes</Link>
                                </li>
                                <li className="nav-item menu-link">
                                    <Link activeClass="active" to="contact" spy={true} offset={-50} smooth={true} duration={500}  className="nav-link">Contato</Link>
                                </li>
                            </ul>

                            {/* <div className="other-option">
                                

                                <div className="sidebar-toggle">
                                    <button type="button" className="btn btn-demo toggle-button navbar-toggle" data-bs-toggle="modal" data-bs-target="#sidebar-right">
                                        <i className="flaticon-list"></i>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default NavComponent
