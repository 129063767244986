import { Link } from "react-router-dom"

const AboutTitleComponent = () => {
    return (
        <div className="about-title">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="title-text text-center">
                        <h2>Sobre Nós</h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>
                                Sobre Nós
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTitleComponent
