const ServiceAboutComponent = () => {
    return (
        <div className="service-section service-style-two">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 service-img">
                    </div>
                    <div className="col-md-6 offset-md-6 p-0">
                        <div className="service-text">
                            <h2>Consultoria e Assessoria Empresarial</h2>
                            <p className="text-white">
                                A BMJ há mais de 35 anos no mercado, possui grande experência no segmento Empresarial, analisando e diagnosticando as melhores soluções para que o seu negócio alcance a melhor performance no segmento.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceAboutComponent
