import CardContactComponent from "../components/cardContact/CardContactComponent"
import ContactComponent from "../components/contact/ContactComponent"
import MapComponent from "../components/map/MapComponent"
import NewslleterComponent from "../components/newslletter/NewsletterComponent"
import ContactTitleComponent from "../components/titles/ContactTitleComponent"

const Contact = () => {
    return (
        <div>
            <ContactTitleComponent />
            <CardContactComponent />
            <ContactComponent />
            <MapComponent />
            <NewslleterComponent />
        </div>
    )
}

export default Contact
