const VideoComponent = () => {
    return (
        <div className="video-section pt-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    {/* <div className="video-text">
                        <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" className="popup-vimeo">
                            <i className="flaticon-play"></i>
                        </a>
                        <p>Assista nosso vídeo e saiba mais sobre nós</p>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    )
}

export default VideoComponent
