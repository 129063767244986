
//Components
import HeaderComponent from './components/header/HeaderComponent';
import NavComponent from './components/nav/NavComponent';

import FooterComponent from './components/footer/FooterComponent';

//Style CSS
import './App.css';
import './assets/css/bootstrap.min.css'
import './assets/css/animate.css'
import './assets/font/flaticon.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/owl.theme.default.min.css'
import './assets/css/magnific-popup.css'
import './assets/css/meanmenu.css'
import './assets/css/style.css'
import './assets/css/dark.css'
import './assets/css/responsive.css'
import './assets/css/whatsapp.css'

//React Rout
import {BrowserRouter, Routes, Route} from 'react-router-dom'


//Pages
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Client from './pages/Client'
import Service from './pages/Service'

function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <BrowserRouter>
      <NavComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/clients" element={<Client />} />
          <Route path="/service" element={<Service />} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
      

      
    </div>
  );
}


export default App;
