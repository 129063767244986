import Theory from "../../assets/img/theory-img.png"

const TheoryComponent = () => {
    return (
        <div id="about" className="theory-section">
            <div className="container">
                <div className="row theory-area">
                    <div className="col-lg-7">
                        <div className="theory-text">
                            <h2>"Seu negócio precisa ser eficiente para ser completo"</h2>

                            <div className="signature-box">
                                <br/>
                                <br/>
                                <p>Milton Salvador de Almeida Júnior</p>
                                <span>Fundador e CEO</span>
                            </div>
                        </div>
                    </div>
                    <div className="theory-img">
                        <img src={Theory} alt="Fundador e CEO"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TheoryComponent
