import { Link } from "react-scroll";
import LogoFooter from "../../assets/img/logo-two.png"


const FooterComponent = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <Link to="/"></Link>
                                    <img src={LogoFooter} width="200" alt="logo"></img>
                            </div>
                            <p>Expertise e compromisso de viabilizar às Empresas a obterem lucros e conquistarem suas metas.</p>

                            <div className="footer-social">
                                <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=100085631325371"><i className="flaticon-facebook"></i></a>
                                <a href="https://twitter.com/BMJconsultores"><i className="flaticon-twitter"></i></a>
                                <a href="https://www.instagram.com/bmjconsultores/"><i className="flaticon-instagram"></i></a>
                                <a href="https://linkedin.com"><i className="flaticon-linkedin"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="footer-widget pl-75">
                            <h3>Institucional</h3>
                            <ul>
                                <li className="menu-link">
                                    <Link to="banner" spy={true} offset={-250} smooth={true} duration={500}>
                                        Home
                                    </Link>
                                </li>
                                <li className="menu-link">
                                    <Link to="about" spy={true} smooth={true} offset={-300} duration={500}>
                                        Sobre Nós
                                    </Link>
                                </li>
                                <li className="menu-link">
                                    <Link to="service" spy={true} offset={-150} smooth={true} duration={500}>
                                        Serviços
                                    </Link>
                                </li>
                                <li className="menu-link">
                                    <Link to="clients" spy={true} smooth={true} offset={-100} duration={500}>
                                        Clientes
                                    </Link>
                                </li>
                                <li className="menu-link">
                                    <Link activeClass="active" to="contact" spy={true} offset={-50} smooth={true} duration={500}>
                                        Contato
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="footer-widget pl-75">
                            <h3>Links Úteis</h3>
                            <ul>
                                <li>
                                    <a href="https://www.receita.fazenda.gov.br/">
                                        Receita Federal
                                    </a>
                                </li>
                                <li>
                                    <a href="https://receita.fazenda.df.gov.br/">
                                        SEFAZ
                                    </a>
                                </li>
                                <li>
                                    <a href="https://cartorios24horas.com.br/">
                                        Cartório 24h
                                    </a>
                                </li>
                                <li>
                                    <a href="https://jucis.df.gov.br/">
                                        Junta Comercial
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tjdft.jus.br/">
                                        TJDFT
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="footer-widget pl-75">
                            <h3>Encontre-nos</h3>
                            <ul>
                                <li>
                                    <a href="tel:+5561998653345">

                                        (61) 99865-3345
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:contato@bmjconsult.srv.br">

                                    contato@bmjconsult.srv.br
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 lh-1 text-left">
                            <p>&copy;2022 BMJ. Todos os Direitos Reservados. Desenvolvido por <a href="https://dobbox.com.br">Dubbox</a></p>
                        </div>
                        <div className="col-md-6 lh-1 text-end">
                            <ul>
                                <li><a href="privacy.html">Política de Privacidade</a></li>
                                <li><a href="termo.html">Termo de Uso</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            

        </footer>
    )
}

export default FooterComponent
