const HeaderComponent = () => {
    return (
        <header className="header-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-md-9">
                        <div className="header-left-text">
                            <ul>
                                <li>
                                    <a href="tel:+0123456789">
                                        <i className="flaticon-call"></i>
                                        (61) 99865-3345
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@everb.com">
                                        <i className="flaticon-envelope"></i>
                                        contato@bmjconsult.srv.br
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-3">
                        <div className="header-right text-end">
                            <div className="header-social">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=100085631325371"><i className="flaticon-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/BMJconsultores"><i className="flaticon-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com.br/"><i className="flaticon-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a href="hhttps://www.instagram.com/bmjconsultores/"><i className="flaticon-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderComponent
