//Components
import BannerComponent from '../components/banner/BannerComponent'
import VideoComponent from '../components/video/VideoComponent';
import TheoryComponent from '../components/theory/TheoryComponent';
import FeaturedComponent from '../components/featured/FeaturedComponent';
import TeamComponent from '../components/team/TeamComponent';
import ContactComponent from '../components/contact/ContactComponent';
import NewslleterComponent from '../components/newslletter/NewsletterComponent';
import ServiceAboutComponent from '../components/service/ServiceAboutComponent';
import ServiceComponent from '../components/service/ServiceComponent';
import ProgressAboutComponent from '../components/progress/ProgressAboutComponent'

//Style CSS
import '../App.css';
import '../assets/css/bootstrap.min.css'
import '../assets/css/animate.css'
import '../assets/font/flaticon.css'
import '../assets/css/owl.carousel.min.css'
import '../assets/css/owl.theme.default.min.css'
import '../assets/css/magnific-popup.css'
import '../assets/css/meanmenu.css'
import '../assets/css/style.css'
import '../assets/css/dark.css'
import '../assets/css/responsive.css'

import CookieConsent from "react-cookie-consent";

const Home = () => {
    return (
        <div>
            <BannerComponent />
            <VideoComponent />
            <TheoryComponent />
            <ServiceAboutComponent />
            <ProgressAboutComponent />
            <ServiceComponent />
            <FeaturedComponent />
            <TeamComponent />
            <ContactComponent />
            <NewslleterComponent />
            <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                Este site usa cookies para melhorar a experência do usuário.{/* {" "}
                <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>

        </div>
    )
}

export default Home
