import Signature from "../../assets/img/signature.png"
import Theory from "../../assets/img/theory-img.png"

const TheoryAboutCompnent = () => {
    return (
        <div className="theory-section theory-style-two">
            <div className="container">
                <div className="row theory-area">
                    <div className="col-lg-7">
                        <div className="theory-text">
                            <h2>My Theory is Customer Service is First</h2>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>

                            <div className="signature-box">
                                <img src={Signature} alt="signature"></img>
                                    <p>Babatunde Smithi</p>
                                    <span>Founder and CEO</span>
                            </div>
                        </div>
                    </div>
                    <div className="theory-img">
                        <img src={Theory} alt="theory"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TheoryAboutCompnent
