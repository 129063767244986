import FeaturedTwoComponent from "../components/featured/FeaturedTwoComponent"
import NewslleterComponent from "../components/newslletter/NewsletterComponent"
import PortifolioComponent from "../components/clients/ClientComponent"
import ServiceTitleComponent from "../components/titles/ServiceTitleComponent"
import VideoTwoComponent from "../components/video/VideoTwoComponent"

const Service = () => {
    return (
        <div>
                <ServiceTitleComponent />
                <FeaturedTwoComponent />
                <VideoTwoComponent />
                <PortifolioComponent />
                <NewslleterComponent />        
        </div>
    )
}

export default Service
