import Portifolio1 from '../../assets/img/portfolio/1.jpg'
import Portifolio2 from '../../assets/img/portfolio/2.jpg'
import Portifolio3 from '../../assets/img/portfolio/3.jpg'
import Portifolio4 from '../../assets/img/portfolio/4.jpg'
import Portifolio5 from '../../assets/img/portfolio/5.jpg'
import Portifolio6 from '../../assets/img/portfolio/6.jpg'

import { Link } from 'react-router-dom'

const PortifolioComponent = () => {
    return (
        <section className="portfolio-section pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="portfolio-item">
                            <div className="portfolio-img">
                                <img src={Portifolio1} alt="portfolio"></img>
                                    <div className="portfolio-text">
                                        <h3>Online Business</h3>
                                        <Link to="/service" className="portfolio-btn">
                                            Veja mais
                                            <i className="flaticon-right-chevron"></i>
                                        </Link>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="portfolio-item">
                            <div className="portfolio-img">
                                <img src={Portifolio2} alt="portfolio2"></img>
                                    <div className="portfolio-text">
                                        <h3>Digital Marketing</h3>
                                        <Link to="/service" className="portfolio-btn">
                                            Veja mais
                                            <i className="flaticon-right-chevron"></i>
                                        </Link>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-8">
                        <div className="portfolio-item">
                            <div className="portfolio-img">
                                <img src={Portifolio3} alt="portfolio3"></img>
                                    <div className="portfolio-text">
                                        <h3>Business Planning</h3>
                                        <Link to="/service" className="portfolio-btn">
                                            Veja mais
                                            <i className="flaticon-right-chevron"></i>
                                        </Link>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-4">
                        <div className="portfolio-item">
                            <div className="portfolio-img">
                                <img src={Portifolio4} alt="portfolio4"></img>
                                    <div className="portfolio-text">
                                        <h3>Content Writing</h3>
                                        <Link to="/service" className="portfolio-btn">
                                            Veja mais
                                            <i className="flaticon-right-chevron"></i>
                                        </Link>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-8">
                        <div className="portfolio-item">
                            <div className="portfolio-img">
                                <img src={Portifolio5} alt="portfolio5"></img>
                                    <div className="portfolio-text">
                                        <h3>Virtual Assistant Service</h3>
                                        <Link to="/service" className="portfolio-btn">
                                            Veja mais
                                            <i className="flaticon-right-chevron"></i>
                                        </Link>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-4">
                        <div className="portfolio-item">
                            <div className="portfolio-img">
                                <img src={Portifolio6} alt="portfolio6"></img>
                                    <div className="portfolio-text">
                                        <h3>SEO Service</h3>
                                        <Link to="/service" className="portfolio-btn">
                                            Veja mais
                                            <i className="flaticon-right-chevron"></i>
                                        </Link>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortifolioComponent
