import Shape2 from "../../assets/img/shapes/2.webp"

const NewslleterComponent = () => {
    return (
        <div className="newsletter-section">
            <div className="container">
                <div className="newsletter-area">
                    <h2>Inscreva-se na Nossa Newsletter</h2>
                    <div className="col-lg-6 offset-lg-3 p-0">
                        <form className="newsletter-form" data-bs-toggle="validator">
                            <input type="email" className="form-control" placeholder="Digite Seu E-mail" name="EMAIL" required autoComplete="off"></input>

                                <button className="default-btn electronics-btn" type="submit">
                                    Inscrever-se
                                </button>

                                <div id="validator-newsletter" className="form-result"></div>
                        </form>
                    </div>
                    <img src={Shape2} className="subscribe-shape" alt="shape"></img>
                </div>
            </div>
        </div>
    )
}

export default NewslleterComponent
